<template>
  <div class="animated fadeIn">
    <v-row class="justify-content-center">
      <v-col cols="11">
        <v-card-title
          style="background-color: transparent !important"
          ref="pageTop"
        >
          <v-row>
            <v-col cols="12" md="6">
              <h4>
                {{ editMode ? "ویرایش" : "جزییات" }} فیش حقوقی
                {{ paySlipData.name }}
              </h4>
            </v-col>
            <v-col cols="12" md="6" class="text-end"
              ><v-btn class="secondary-btn pa-5" @click="$router.go(-1)">
                <span>
                  بازگشت
                  <v-icon class="ms-1">mdi-arrow-left</v-icon>
                </span>
              </v-btn></v-col
            >
          </v-row>
        </v-card-title>
        <v-card v-if="gettingData" class="cards pa-6">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="40"
            ></v-progress-circular>
            <h6 class="mt-6">در حال بارگذاری ...</h6>
          </div>
        </v-card>
        <v-card class="cards pa-6" v-else>
          <div v-if="!editMode">
            <h5 class="mb-4">اطلاعات فیش</h5>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد روز کارکرد: {{ paySlipData.workDays }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد ساعات کارکرد: {{ paySlipData.workHours }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ شروع فیش حقوقی: {{ paySlipData.startDate }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ پایان فیش حقوقی: {{ paySlipData.endDate }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>
                    وضعیت تاهل :
                    {{
                      maritalStatuses.filter(
                        (x) => x.value == paySlipData.maritalStatus
                      )[0]
                        ? maritalStatuses.filter(
                            (x) => x.value == paySlipData.maritalStatus
                          )[0].text
                        : "-"
                    }}
                  </h6>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div
                  class="disabled-fields grey lighten-3"
                  v-if="paySlipData.maritalStatus == 'married'"
                >
                  <h6>تعداد فرزند : {{ paySlipData.numberOfChildren }}</h6>
                </div>
              </v-col>
            </v-row>
            <hr class="my-4" />
            <h5 class="mb-4">حقوق و مزایا</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg cyan lighten-5">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق پایه ساعتی
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.salaryPerHour).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق ماهیانه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ Number(paySlipData.salary).toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق مسکن
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.houseFee).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    کمک هزینه اقلام مصرفی خانوار
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{
                        Number(paySlipData.familySupplements).toLocaleString()
                      }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    اضافه کاری
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.overTime).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول بیمه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{
                        calculateCoveredByInsurance(
                          "paySlipData"
                        ).toLocaleString()
                      }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق اولاد
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.childrenFee).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول مالیات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{
                        calculateCoveredByTax("paySlipData").toLocaleString()
                      }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق ماموریت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.missionFee).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل حقوق و مزایا
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{ calculateTotalSalary("paySlipData").toLocaleString() }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <hr class="my-4" />
            <h5 class="mb-4">کسورات</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg orange lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مالیات بر حقوق
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.taxFee).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    وام
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.loan).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مساعده
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.imprest).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    7% سهم بیمه کارگر
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ calculateWorkerFee("paySlipData").toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    غیبت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.absence).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    سایر کسورات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.otherFees).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل کسورات
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{
                        calculateTotalDeductions("paySlipData").toLocaleString()
                      }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 py-8 ps-16 pe-16 rounded-lg green lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon class="teal--text text--darken-2"
                      >mdi-check-decagram</v-icon
                    >
                    خالص پرداختی
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{ calculateTotalAmount("paySlipData").toLocaleString() }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 text-end">
              <v-btn
                class="green-btn"
                width="150"
                height="50"
                @click="changeToEditMode()"
              >
                <v-icon class="me-1">mdi-check-underline</v-icon>
                <h6>ویرایش فیش</h6>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <h5 class="mb-4">اطلاعات فیش</h5>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد روز کارکرد: {{ paySlipData.workDays }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تعداد ساعات کارکرد: {{ paySlipData.workHours }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ شروع فیش حقوقی: {{ paySlipData.startDate }}</h6>
                </div></v-col
              >
              <v-col cols="12" md="6">
                <div class="disabled-fields grey lighten-3">
                  <h6>تاریخ پایان فیش حقوقی: {{ paySlipData.endDate }}</h6>
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="editData.maritalStatus"
                  :items="maritalStatuses"
                  item-text="text"
                  item-value="value"
                  label="وضعیت تاهل"
                  outlined
                  dense
                  hide-details
                ></v-select
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="editData.maritalStatus == 'married'"
                  v-model="editData.numberOfChildren"
                  label="تعداد فرزند"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
            <hr class="my-4" />
            <h5 class="mb-4">حقوق و مزایا</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg cyan lighten-5">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق پایه ساعتی
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ Number(paySlipData.salaryPerHour).toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حقوق ماهیانه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>{{ Number(paySlipData.salary).toLocaleString() }}</h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق مسکن
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.houseFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    کمک هزینه اقلام مصرفی خانوار
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.familySupplements"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    اضافه کاری
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.overTime"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول بیمه
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{
                        calculateCoveredByInsurance("editData").toLocaleString()
                      }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق اولاد
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.childrenFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6 class="font-weight-bold">
                    <v-icon>mdi-cash-check</v-icon>
                    جمع حقوق و مزایای مشمول مالیات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ calculateCoveredByTax("editData").toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    حق ماموریت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.missionFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل حقوق و مزایا
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{ calculateTotalSalary("editData").toLocaleString() }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <hr class="my-4" />
            <h5 class="mb-4">کسورات</h5>
            <div class="py-8 ps-16 pe-16 rounded-lg orange lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مالیات بر حقوق
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.taxFee"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    وام
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.loan"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    مساعده
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.imprest"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    7% سهم بیمه کارگر
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h6>
                      {{ calculateWorkerFee("editData").toLocaleString() }}
                    </h6>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    غیبت
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.absence"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h6>
                    <v-icon>mdi-cash-check</v-icon>
                    سایر کسورات
                  </h6></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <vuetify-money
                    v-model="editData.otherFees"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    class="w-100"
                    outlined
                    dense
                    hide-details
                  ></vuetify-money>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
              <hr class="my-5" />
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon>mdi-cash-plus</v-icon>
                    کل کسورات
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{
                        calculateTotalDeductions("editData").toLocaleString()
                      }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 py-8 ps-16 pe-16 rounded-lg green lighten-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-items-center"
                  ><h5 class="font-weight-bold">
                    <v-icon class="teal--text text--darken-2"
                      >mdi-check-decagram</v-icon
                    >
                    خالص پرداختی
                  </h5></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-items-center">
                  <div class="disabled-fields grey lighten-3">
                    <h5>
                      {{ calculateTotalAmount("editData").toLocaleString() }}
                    </h5>
                  </div>
                  <h6 class="ms-2">ریال</h6></v-col
                >
              </v-row>
            </div>
            <div class="mt-4 text-end">
              <v-btn
                class="green-btn"
                width="180"
                height="50"
                @click="editPaySlip()"
                :loading="submitLoading"
              >
                <v-icon class="me-1">mdi-check-underline</v-icon>
                <h6>ذخیره‌سازی تغییرات</h6>
              </v-btn>
              <v-btn
                class="red-btn ms-2"
                width="60"
                height="50"
                @click="cancelEditMode()"
              >
                <h6>لغو</h6>
              </v-btn>
            </div>
          </div>
        </v-card></v-col
      ></v-row
    >
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      userInfo: {},
      dateFrom: moment(new Date()).subtract(30, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      gettingData: false,
      paySlipData: {
        name: "آرینا شهبازی",
        salaryPerHour: "5000",
        salary: "2000",
        houseFee: "1122",
        familySupplements: "1000",
        overTime: "0",
        childrenFee: "0",
        missionFee: "0",
        taxFee: "0",
        loan: "0",
        imprest: "0",
        otherFees: "0",
        absence: "0",
      },
      moment: moment,
      showForm: false,
      maritalStatuses: [
        { value: "married", text: "متاهل" },
        { value: "single", text: "مجرد" },
      ],
      submitLoading: false,
      editMode: false,
      editData: {},
    };
  },
  methods: {
    getData() {
      this.gettingData = true;
      this.$http
        .get(
          this.baseUrl + "/clinic/hr/payslip/getData/" + this.$route.params.id,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.paySlipData = res.data;
          this.editData = JSON.parse(JSON.stringify(res.data));
          this.gettingData = false;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.gettingData = false;
        });
    },
    changeToEditMode() {
      this.editData = JSON.parse(JSON.stringify(this.paySlipData));
      this.editMode = true;
      this.$refs["pageTop"].scrollIntoView({ behavior: "smooth" });
    },
    cancelEditMode() {
      this.editMode = false;
      this.$refs["pageTop"].scrollIntoView({ behavior: "smooth" });
    },
    calculateCoveredByInsurance(dataName) {
      return (
        Number(this[dataName].salaryPerHour) +
        Number(this[dataName].salary) +
        Number(this[dataName].houseFee) +
        Number(this[dataName].familySupplements) +
        Number(this[dataName].overTime)
      );
    },
    calculateCoveredByTax(dataName) {
      return (
        Number(this[dataName].childrenFee) +
        this.calculateCoveredByInsurance(dataName)
      );
    },
    calculateTotalSalary(dataName) {
      return (
        Number(this[dataName].missionFee) + this.calculateCoveredByTax(dataName)
      );
    },
    calculateWorkerFee(dataName) {
      return 0.07 * this.calculateCoveredByInsurance(dataName);
    },
    calculateTotalDeductions(dataName) {
      return (
        Number(this[dataName].taxFee) +
        Number(this[dataName].loan) +
        Number(this[dataName].imprest) +
        Number(this[dataName].absence) +
        Number(this[dataName].otherFees) +
        this.calculateWorkerFee(dataName)
      );
    },
    calculateTotalAmount(dataName) {
      return (
        this.calculateTotalSalary(dataName) -
        this.calculateTotalDeductions(dataName)
      );
    },
    editPaySlip() {
      this.submitLoading = true;
      this.$http
        .patch(
          this.baseUrl + "/clinic/hr/payslip/edit/" + this.$route.params.id,
          {
            userId: this.paySlipData.userId,
            name: this.paySlipData.name,
            workDays: this.paySlipData.workDays,
            workHours: this.paySlipData.workHours,
            startDate: this.paySlipData.startDate,
            endDate: this.paySlipData.endDate,
            maritalStatus: this.editData.maritalStatus,
            numberOfChildren:
              this.editData.maritalStatus == "married"
                ? Number(this.editData.numberOfChildren)
                : 0,
            salaryPerHour: this.paySlipData.salaryPerHour,
            salary: this.paySlipData.salary,
            houseFee: Number(this.editData.houseFee),
            familySupplements: Number(this.editData.familySupplements),
            overTime: Number(this.editData.overTime),
            childrenFee: Number(this.editData.childrenFee),
            missionFee: Number(this.editData.missionFee),
            taxFee: Number(this.editData.taxFee),
            loan: Number(this.editData.loan),
            imprest: Number(this.editData.imprest),
            workerRightFee: Number(this.calculateWorkerFee("editData")),
            absence: Number(this.editData.absence),
            otherFees: Number(this.editData.otherFees),
            paidAmount: Number(this.calculateTotalAmount("editData")),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.submitLoading = false;
          if (res.status == 200) {
            this.toast(res.data.message, "success");
            localStorage.setItem("previousPage", "newPaySlip");
            this.$router.go(-1);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.submitLoading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss"></style>
